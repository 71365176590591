<template>
  <div>
    <el-form class="firStepForm" :model="shouhuodizhiInfo" :rules="formRules" ref="formRefdd">
      <ul>
        <li>
          <el-form-item prop="contacts" :label="$fanyi('收件人')">
            <input class="ipt" v-bind:class="{
              istrueInput: formInputTrue.contacts,
            }" @focus="validateField('contacts', 'formRefdd')" @blur="validateField('contacts', 'formRefdd')"
              autocomplete="new-password" v-model="shouhuodizhiInfo.contacts" />
            <div class="gou" v-if="formInputTrue.contacts">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item class="zhou" prop="state" :label="$fanyi('州')" v-bind:class="{
            istrueInput: formInputTrue.country,
          }">
            <!--            <input-->
            <!--              class="ipt"-->
            <!--              v-bind:class="{-->
            <!--                istrueInput: formInputTrue.country,-->
            <!--              }"-->
            <!--              @focus="validateField('country', 'formRefdd')"-->
            <!--              @blur="validateField('country', 'formRefdd')"-->
            <!--              autocomplete="new-password"-->
            <!--              v-model="shouhuodizhiInfo.country"-->
            <!--            />-->
            <!--            <div class="gou" v-if="formInputTrue.country">✔</div>-->
            <el-select class="xuanZeGuoJia " name="" v-bind:class="{
              istrueInput: formInputTrue.state,
            }" filterable @change="validateField('state', 'formRefdd')" @blur="validateField('state', 'formRefdd')"
              v-model="shouhuodizhiInfo.state" :placeholder="$fanyi('请选择地区')">
              <el-option :label="item" :value="item" v-for="(item, index) in $imdata.provincesList" :key="item + index">
              </el-option>
            </el-select>
            <div class="gou" v-if="formInputTrue.state" style="margin-right: 20px">
              ✔
            </div>
          </el-form-item>
        </li>
        <li>
          <div class="formZiDingYi">
            <el-form-item prop="zip_code" :label="$fanyi('邮编')">
              <div class="youbian">
                <input class="ipt" type="number" v-bind:class="{
                  istrueInput: formInputTrue.zip_code,
                }" @focus="validateField('zip_code', 'formRefdd')" @blur="validateField('zip_code', 'formRefdd')"
                  autocomplete="new-password" v-model="shouhuodizhiInfo.zip_code" />
                <div class="gou" v-if="formInputTrue.zip_code">✔</div>
              </div>
            </el-form-item>
            <el-form-item prop="city" :label="$fanyi('城市')">
              <div class="chengshi">
                <input class="ipt" v-bind:class="{
                  istrueInput: formInputTrue.city,
                }" @focus="validateField('city', 'formRefdd')" @blur="validateField('city', 'formRefdd')"
                  autocomplete="new-password" v-model="shouhuodizhiInfo.city" />
                <div class="gou" v-if="formInputTrue.city">✔</div>
              </div>
            </el-form-item>
          </div>
        </li>
        <li>
          <el-form-item prop="address" :label="$fanyi('地址')">
            <el-popover placement="top-start" width="360" trigger="hover" :content="shouhuodizhiInfo.address">
              <input slot="reference" class="ipt" v-bind:class="{
                istrueInput: formInputTrue.address,
              }" @focus="validateField('address', 'formRefdd')" @blur="validateField('address', 'formRefdd')"
                autocomplete="new-password" v-model="shouhuodizhiInfo.address" />
            </el-popover>
            <div class="gou" v-if="formInputTrue.address">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item prop="mobile" :label="$fanyi('手机号')">
            <input class="ipt" type="number" v-bind:class="{
              istrueInput: formInputTrue.mobile,
            }" @focus="validateField('mobile', 'formRefdd')" @blur="validateField('mobile', 'formRefdd')"
              autocomplete="new-password" v-model="shouhuodizhiInfo.mobile" />
            <div class="gou" v-if="formInputTrue.mobile">✔</div>
          </el-form-item>
        </li>

        <li>
          <el-form-item prop="email" :label="$fanyi('邮箱')">
            <input class="ipt" v-bind:class="{
              istrueInput: formInputTrue.email,
            }" @focus="validateField('email', 'formRefdd')
cxzcxz()
  " @blur="
    validateField('email', 'formRefdd')
  cxzcxz()
    " autocomplete="new-password" v-model="shouhuodizhiInfo.email" />
            <div class="gou" v-if="formInputTrue.email">✔</div>
          </el-form-item>
        </li>
      </ul>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ['shouhuodizhi', 'timer'],
  data() {
    // 邮箱格式检验
    var emailFormatVerify = (rule, value, callback) => {
      let emailPostfixList = [
        'qq.com',
        '163.com',
        'fastmail.com',
        'me.com',
        'email.com',
        'gmail.com',
        '126.com',
        'yahoo.com',
        'sina.com',
        'games.com',
        'rocketmail.com',
        'wow.com',
        'googlemail.com',
        'comcast.net',
        'warwick.net',
        'comcast.net',
        'twcny.rr.com',
        'hotmail.com',
        'foxmail.com',
        'rakumartwork.com',
        'outlook.com',
        'facebook.com',
        'mail.com',
        'mac.com',
        'icloud.com',
        'zoho.com',
        'ymail.com',
        'ygm.com',
        'love.com',
        'aol.com',
        'verizon.net',
        'cs.com',
        'netzero.net',
        'rakumart.eu',
      ]
      let index = value.indexOf('@')
      let str = value.substr(index + 1, value.length)
      if (emailPostfixList.indexOf(str) === -1) {
        callback(new Error(this.$fanyi('请输入正确的邮箱地址')))
      } else {
        callback()
      }
    }
    return {
      shouhuodizhiInfo: this.shouhuodizhi,
      formInputTrue: {}, //输入框样式控制数据
      // 输入框限制控制
      formRules: {
        company: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        zip_code: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
          {
            pattern: /^(\w){5}$/,
            message: this.$fanyi('邮编输入错误'),
          },
        ],
        address: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        city: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        mobile: [
          // { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
          {
            required: true,
            pattern: /^(\w){10}$/,
            message: this.$fanyi('请输入正确的手机号'),
          },
        ],
        email: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
          {
            type: 'email',
            message: this.$fanyi('请输入正确的邮箱地址'),
            trigger: ['blur', 'change'],
          },
          {
            validator: emailFormatVerify,
            trigger: 'blur',
          },
        ],
        state: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        cnpj: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
          {
            required: true,
            pattern: /^(\w){5}$/,
            message: this.$fanyi('SSN输入错误'),
          },
        ],
      },
    }
  },
  components: {},
  computed: {
    // 获取父元素数据
    formregister() {
      console.log(this.$parent.shouhuodizhi)
      return (
        this.$parent.shouhuodizhi || this.$parent.$parent.$parent.shouhuodizhi //页面上和弹窗上数据获取位置不同所以写一个或者
      )
    },
  },
  created() {
    console.log(this.shouhuodizhiInfo)

    for (let key in this.shouhuodizhiInfo) {
      // console.log(key)
      // console.log(address[key])
      if (this.shouhuodizhiInfo[key] !== '') {
        this.formInputTrue[key] = true
      }
    }
    // this.$api.useraddressList().then((res) => {
    //   if (res.code != 0) return this.$message.error(res.msg)
    //   // 如果moren参数为true，就遍历地址数据里的两个地址列表寻找默认地址
    //   console.log(res.data.consignee)
    //   res.data.consignee.forEach((item, index) => {
    //     if (item.id === this.$parent.actNum.shouhuodizhi) {
    //       this.formInputTrue.contacts = true
    //       this.formInputTrue.company = true
    //       this.formInputTrue.cnpj = true
    //       this.formInputTrue.mobile = true
    //       this.formInputTrue.email = true
    //       this.formInputTrue.address = true
    //       this.formInputTrue.zip_code = true
    //       this.formInputTrue.city = true
    //       this.formInputTrue.country = true
    //       this.istrueInput = true
    //       console.log(item)
    //       if (item.acquiescent) {
    //         this.shouhuodizhiInfo = item
    //       }
    //       this.$forceUpdate()
    //     }
    //   })
    //   setTimeout(() => {
    //     this.checkAllInput()
    //   }, 500)
    // })
    // console.log(this.formregister)
  },
  mounted() {
    // if (this.$parent.$parent.$parent.jiancha) {
    //   this.checkAllInput();
    // }
  },
  methods: {
    morendizhi() {
      for (let key in this.shouhuodizhiInfo) {
        if (this.shouhuodizhiInfo[key] !== '') {
          this.formInputTrue[key] = true
        }
        // console.log(this.formInputTrue)
      }
    },
    checkAllInput() {
      let checkOpt = [
        'address',
        'city',
        'contacts',
        'state',
        'email',
        'mobile',
        'zip_code',
      ]
      for (let i in checkOpt) {
        this.validateField(checkOpt[i], 'formRefdd')
      }
    },
    cxzcxz() {
      console.log(111)
    },
    // 手动检验数据函数
    validateField(type, formRef) {
      console.log(type, formRef)
      if (this.shouhuodizhiInfo[type]) {
        console.log(this.shouhuodizhiInfo[type]);
        this.shouhuodizhiInfo[type] = this.shouhuodizhiInfo[type].trim()
      }

      this.$refs[formRef].validateField(type, (v) => {
        console.log(v)
        if (v == '') {
          console.log(this.formInputTrue[type])
          this.formInputTrue[type] = true
        } else {
          this.formInputTrue[type] = false
        }
        this.$forceUpdate()
      })
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../css/mixin.scss';

.istrueInput {
  /deep/ .el-input__inner {
    background: #fff2e6 !important;
    border: 1px solid rgba(255, 115, 11, 0.4) !important;
    background: #ffffff;
    border-radius: 6px !important;

    //border-radius: 5px;
    position: relative;
  }
}

.firStepForm {
  width: 740px;

  /deep/.el-form-item {
    margin-bottom: 30px;
  }

  /deep/.el-form-item__error {
    top: 50px;
  }

  >ul {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    >li {
      width: 360px;
      display: flex;
      flex-direction: column;

      // 表单标题样式
      /deep/.el-form-item__label {
        padding: 0;
        text-align: left;
        float: none;
        display: block;
        width: 100%;
        line-height: 14px;
        height: 14px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;

        &::before {
          display: none;
        }
      }

      /deep/.elistrueInput .el-input__inner {
        background: #fff2e6;
        border: 1px solid rgba(255, 115, 11, 0.4);
        border-radius: 0;
        //border-radius: 5px;
        position: relative;
      }

      input {
        width: 360px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #dedede;
        font-size: 14px;
        font-weight: 400;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        //border-radius: 5px;
        padding: 0 15px;
        transition: 0.4s;

        &::placeholder {
          color: #b6b6b6;
        }

        &:focus {
          border: 1px solid $homePageSubjectColor;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
        }

        &.istrueInput {
          background: #fff2e6;
          border: 1px solid rgba(255, 115, 11, 0.4) !important;
          border-radius: 0;
          border-radius: 6px;
          position: relative;
        }
      }

      .gou {
        position: absolute;
        font-weight: bold;
        right: 13px;
        top: 3px;
        color: #ff730b;

        &.regCodegou {
          right: 222px;
        }
      }

      .xuanZeGuoJia {
        width: 100%;
        height: 48px;
        // background: #fff2e6;
        border-radius: 6px !important;
        //border-radius: 5px;

        /deep/.el-input__inner {
          width: 100%;
          height: 48px;

          // border: 1px solid rgba(255, 115, 11, 0.4);
          border-radius: 6px;
          //border-radius: 5px;
        }

        /deep/.el-icon-arrow-up {
          color: $homePageSubjectColor;
          font-weight: bold;
          font-size: 15px;
        }
      }

      .formZiDingYi {
        //多个输入框自定义样式
        display: flex;
        align-items: center;
        margin-top: -14px;

        .youbian {
          margin-right: 20px;

          input {
            width: 130px;
          }

          .gou {
            right: 33px;
          }
        }

        .chengshi {
          input {
            width: 210px;
          }
        }
      }
    }

    .guoJiaQuHao {
      .shouJIHaoCon {
        display: flex;
        align-items: center;

        .quHao {
          width: 58px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 0;
          //border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          &.istrueInput {
            background: #fff2e6;
            border: 1px solid rgba(255, 115, 11, 0.4);
            border-radius: 0;
            //border-radius: 5px;
            position: relative;
          }
        }

        input {
          width: 312px;
        }
      }
    }

    .yanzhengma {
      .yanzhengmaBox {
        display: flex;
        align-items: center;

        input {
          width: 167px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 0;

          //border-radius: 5px;
          &.istrueInput {
            background: #fff2e6 !important;
            border: 1px solid rgba(255, 115, 11, 0.4);
            border-radius: 0;
            //border-radius: 5px;
            position: relative;
          }
        }

        button {
          margin-left: 80px;
          width: 195px;
          height: 48px;
          background: $homePageSubjectColor;
          border: 1px solid $homePageSubjectColor;
          border-radius: 0;
          //border-radius: 5px;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }

    .xieYi {
      width: 360px;
      display: flex;
      align-items: center;

      .fuWuXieYi {
        display: flex;
        align-items: center;

        a {
          width: 165px;
          height: 35px;
          font-size: 14px;

          font-weight: 300;
          color: #2f2f2f;
          line-height: 35px;
          margin: 0 5px 0 10px;
        }

        button {
          width: 182px;
          height: 48px;
          background: #47bd9b;
          border: 1px solid #47bd9b;
          border-radius: 0;
          //border-radius: 5px;
          color: white;
        }
      }
    }

    .xiaYiBuHuoFanHui {
      .caoZuo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          width: 45%;
          height: 48px;
          background: #47bd9b;
          border: 1px solid #47bd9b;
          border-radius: 0;
          //border-radius: 5px;
          color: white;
        }

        .shangYiBu {
          background: $homePageSubjectColor;
        }

        .xiaYiBu {
          background: #47bd9b;
        }
      }
    }
  }

  .showregisterInfo {
    width: 360px;
    height: 48px;
    font-size: 13px;
    line-height: 48px;
    border: 1px solid #dedede;
    background-color: #f3f3f3;
    // color: #787777;
    border-radius: 0;
    //border-radius: 5px;
    padding: 0 10px;
    transition: 0.4s;
  }
}

.ipt {
  padding-right: 30px !important;
}
</style>
