var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"formRefdd",staticClass:"firStepForm",attrs:{"model":_vm.shouhuodizhiInfo,"rules":_vm.formRules}},[_c('ul',[_c('li',[_c('el-form-item',{attrs:{"prop":"contacts","label":_vm.$fanyi('收件人')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shouhuodizhiInfo.contacts),expression:"shouhuodizhiInfo.contacts"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.contacts,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.shouhuodizhiInfo.contacts)},on:{"focus":function($event){return _vm.validateField('contacts', 'formRefdd')},"blur":function($event){return _vm.validateField('contacts', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shouhuodizhiInfo, "contacts", $event.target.value)}}}),(_vm.formInputTrue.contacts)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{staticClass:"zhou",class:{
            istrueInput: _vm.formInputTrue.country,
          },attrs:{"prop":"state","label":_vm.$fanyi('州')}},[_c('el-select',{staticClass:"xuanZeGuoJia ",class:{
              istrueInput: _vm.formInputTrue.state,
            },attrs:{"name":"","filterable":"","placeholder":_vm.$fanyi('请选择地区')},on:{"change":function($event){return _vm.validateField('state', 'formRefdd')},"blur":function($event){return _vm.validateField('state', 'formRefdd')}},model:{value:(_vm.shouhuodizhiInfo.state),callback:function ($$v) {_vm.$set(_vm.shouhuodizhiInfo, "state", $$v)},expression:"shouhuodizhiInfo.state"}},_vm._l((_vm.$imdata.provincesList),function(item,index){return _c('el-option',{key:item + index,attrs:{"label":item,"value":item}})}),1),(_vm.formInputTrue.state)?_c('div',{staticClass:"gou",staticStyle:{"margin-right":"20px"}},[_vm._v(" ✔ ")]):_vm._e()],1)],1),_c('li',[_c('div',{staticClass:"formZiDingYi"},[_c('el-form-item',{attrs:{"prop":"zip_code","label":_vm.$fanyi('邮编')}},[_c('div',{staticClass:"youbian"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shouhuodizhiInfo.zip_code),expression:"shouhuodizhiInfo.zip_code"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.zip_code,
                },attrs:{"type":"number","autocomplete":"new-password"},domProps:{"value":(_vm.shouhuodizhiInfo.zip_code)},on:{"focus":function($event){return _vm.validateField('zip_code', 'formRefdd')},"blur":function($event){return _vm.validateField('zip_code', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shouhuodizhiInfo, "zip_code", $event.target.value)}}}),(_vm.formInputTrue.zip_code)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])]),_c('el-form-item',{attrs:{"prop":"city","label":_vm.$fanyi('城市')}},[_c('div',{staticClass:"chengshi"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shouhuodizhiInfo.city),expression:"shouhuodizhiInfo.city"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.city,
                },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.shouhuodizhiInfo.city)},on:{"focus":function($event){return _vm.validateField('city', 'formRefdd')},"blur":function($event){return _vm.validateField('city', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shouhuodizhiInfo, "city", $event.target.value)}}}),(_vm.formInputTrue.city)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])])],1)]),_c('li',[_c('el-form-item',{attrs:{"prop":"address","label":_vm.$fanyi('地址')}},[_c('el-popover',{attrs:{"placement":"top-start","width":"360","trigger":"hover","content":_vm.shouhuodizhiInfo.address}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shouhuodizhiInfo.address),expression:"shouhuodizhiInfo.address"}],staticClass:"ipt",class:{
                istrueInput: _vm.formInputTrue.address,
              },attrs:{"slot":"reference","autocomplete":"new-password"},domProps:{"value":(_vm.shouhuodizhiInfo.address)},on:{"focus":function($event){return _vm.validateField('address', 'formRefdd')},"blur":function($event){return _vm.validateField('address', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shouhuodizhiInfo, "address", $event.target.value)}},slot:"reference"})]),(_vm.formInputTrue.address)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()],1)],1),_c('li',[_c('el-form-item',{attrs:{"prop":"mobile","label":_vm.$fanyi('手机号')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shouhuodizhiInfo.mobile),expression:"shouhuodizhiInfo.mobile"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.mobile,
            },attrs:{"type":"number","autocomplete":"new-password"},domProps:{"value":(_vm.shouhuodizhiInfo.mobile)},on:{"focus":function($event){return _vm.validateField('mobile', 'formRefdd')},"blur":function($event){return _vm.validateField('mobile', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shouhuodizhiInfo, "mobile", $event.target.value)}}}),(_vm.formInputTrue.mobile)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"email","label":_vm.$fanyi('邮箱')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shouhuodizhiInfo.email),expression:"shouhuodizhiInfo.email"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.email,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.shouhuodizhiInfo.email)},on:{"focus":function($event){_vm.validateField('email', 'formRefdd')
_vm.cxzcxz()},"blur":function($event){_vm.validateField('email', 'formRefdd')
  _vm.cxzcxz()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shouhuodizhiInfo, "email", $event.target.value)}}}),(_vm.formInputTrue.email)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }