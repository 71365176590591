var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"formRef",staticClass:"firStepForm",attrs:{"model":_vm.jinkoushangInfo,"rules":_vm.formRulesPay}},[_c('ul',[_c('li',[_c('el-form-item',{attrs:{"prop":"contacts","label":_vm.$fanyi('全名')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.contacts),expression:"jinkoushangInfo.contacts"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.contacts,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.contacts)},on:{"focus":function($event){return _vm.validateField('contacts')},"blur":function($event){return _vm.validateField('contacts')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "contacts", $event.target.value)}}}),(_vm.formInputTrue.contacts)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"company","label":_vm.$fanyi('公司(可选)')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.company),expression:"jinkoushangInfo.company"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.company,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.company)},on:{"focus":function($event){return _vm.validateField('company')},"blur":function($event){return _vm.validateField('company')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "company", $event.target.value)}}}),(_vm.formInputTrue.company)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"cnpj","label":_vm.$fanyi('SSN')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.cnpj),expression:"jinkoushangInfo.cnpj"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.cnpj,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.cnpj)},on:{"focus":function($event){return _vm.validateField('cnpj')},"blur":function($event){return _vm.validateField('cnpj')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "cnpj", $event.target.value)}}}),(_vm.formInputTrue.cnpj)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"mobile","label":_vm.$fanyi('电话')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.mobile),expression:"jinkoushangInfo.mobile"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.mobile,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.mobile)},on:{"focus":function($event){return _vm.validateField('mobile')},"blur":function($event){return _vm.validateField('mobile')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "mobile", $event.target.value)}}}),(_vm.formInputTrue.mobile)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"email","label":_vm.$fanyi('邮件箱')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.email),expression:"jinkoushangInfo.email"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.email,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.email)},on:{"focus":function($event){return _vm.validateField('email')},"blur":function($event){return _vm.validateField('email')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "email", $event.target.value)}}}),(_vm.formInputTrue.email)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"address","label":_vm.$fanyi('地址')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.address),expression:"jinkoushangInfo.address"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.address,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.address)},on:{"focus":function($event){return _vm.validateField('address')},"blur":function($event){return _vm.validateField('address')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "address", $event.target.value)}}}),(_vm.formInputTrue.address)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('div',{staticClass:"formZiDingYi"},[_c('el-form-item',{attrs:{"prop":"zip_code","label":_vm.$fanyi('邮编')}},[_c('div',{staticClass:"youbian"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.zip_code),expression:"jinkoushangInfo.zip_code"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.zip_code,
                },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.zip_code)},on:{"focus":function($event){return _vm.validateField('zip_code')},"blur":function($event){return _vm.validateField('zip_code')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "zip_code", $event.target.value)}}}),(_vm.formInputTrue.zip_code)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])]),_c('el-form-item',{attrs:{"prop":"city","label":_vm.$fanyi('城市')}},[_c('div',{staticClass:"chengshi"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.city),expression:"jinkoushangInfo.city"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.city,
                },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.city)},on:{"focus":function($event){return _vm.validateField('city')},"blur":function($event){return _vm.validateField('city')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "city", $event.target.value)}}}),(_vm.formInputTrue.city)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])])],1)]),_c('li',[_c('el-form-item',{attrs:{"prop":"country","label":_vm.$fanyi('国家')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jinkoushangInfo.country),expression:"jinkoushangInfo.country"}],staticClass:"ipt",class:{
              istrueInput: _vm.formInputTrue.country,
            },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.jinkoushangInfo.country)},on:{"focus":function($event){return _vm.validateField('country')},"blur":function($event){return _vm.validateField('country')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.jinkoushangInfo, "country", $event.target.value)}}}),(_vm.formInputTrue.country)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }